import { Button, Card } from "react-bootstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "../../Enums/Tranlate";
import Loader from "../../common/Loader";
import uploadImg from "../../../images/upload-img.png";
import BaseService from "../../../services/BaseService";
import { toast } from "react-toastify";
import './style.scss'
import BrandingService from "../../../services/BrandingService";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'draft-js/dist/Draft.css';

const Branding = () => {
  const [formData, setFormData] = useState({
        wwd_description: EditorState.createEmpty(),
        services: [
            {title: "", img: { src: "", loading: false }},
            {title: "", img: { src: "", loading: false }},
            {title: "", img: { src: "", loading: false }},
        ],
        banners: [
          { src: "", title: "", sub_title: "", loading: false },
          { src: "", title: "", sub_title: "", loading: false },
          { src: "", title: "", sub_title: "", loading: false },
          { src: "", title: "", sub_title: "", loading: false },
          { src: "", title: "", sub_title: "", loading: false },
      ],
        our_partners: [
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
          { src: "", loading: false },
      ]
  })
  const [loading, setLoading] = useState(false)
  const [WWDLoading, setWWDLoading] = useState(false)
  const [bannersLoading, setBannersLoading] = useState(false)
  const [editBanners, setEditBanners] = useState(false)
  const [partnersLoading, setPartnersLoading] = useState(false)
  const lang = useSelector((state) => state.auth?.lang);
  const brandingService = new BrandingService()
  const Auth = useSelector(state=> state.auth?.auth)
  const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

  useEffect(()=>{
    let banners = brandingService?.getBanners()
    let wwd = brandingService?.getWWD()
    let services = brandingService?.getServices()
    let images = brandingService?.getImages()

    Promise.all([banners, wwd, services, images]).then(res=>{
      let data = {}
      if(res[0]?.status === 200){
        const banners = res[0]?.data?.data?.map(info=>{
          return {
            ...info,
            src: info?.image
          }
        });
        if(banners?.length > 0) setEditBanners(true)
        if(banners?.length < 5){
          let complete =[]
          for(let i=banners?.length; i<5; i++){
              complete.push({src: "", title: "", sub_title: "", loading: false})
          }
          data['banners']= [...banners, ...complete]
      } else {
        data['banners']= [...banners]
      }
      }

      if(res[1]?.status === 200){
        data['wwd_description'] = EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(res[1]?.data?.data?.description)))
      }

      if(res[2]?.status === 200){
        data['services'] = Array.from({ length: 3 }, (_, index) => {
          let arr = res[2]?.data?.data;
          if(arr[index]){
            return {
              title: arr[index]?.title,
              img: { src: arr[index]?.image, loading: false }
            }
          } else {
            return {
              title: "",
              img: { src: "", loading: false }
            }
          }
        })
      }

      if(res[3]?.status === 200){
        if(res[3]?.data?.data?.length > 9){
          data['our_partners'] = res[2]?.data?.data?.map(part => {
            return {id: part?.id, src: part?.image, loading: false }
          })
        } else {
          data['our_partners'] = Array.from({ length: 9 }, (_, index) => {
            let arr = res[3]?.data?.data;
            if(arr[index]){
              return {id: arr[index]?.id, src: arr[index]?.image, loading: false }
            } else {
              return { src: "", loading: false }
            }
          })
        }
      }

      setFormData({...formData, ...data})
    }).catch(() => {});
  }, [])

  const fileHandler = (e, index, type) => {
    if(e.target.files?.length === 0){
      return
    }
    let filesAll = e.target.files;
    const filesData = Object.values(filesAll);
    if(type === "banners"){
      let update = formData?.banners?.map((data, ind)=> {
        if(ind === index){
          return {...data, src: "", loading: true}
        } else{
           return data
        }
      })
      setFormData({ ...formData,  banners: update });
    } else if(type === "services"){
      let update = formData?.services?.map((data, ind)=> {
        if(ind === index){
          return {title: "", img: { src: "", loading: true }}
        } else{
           return data
        }
      })
      setFormData({  ...formData, services: update });
    } else {
      let update = formData?.our_partners?.map((data, ind)=> {
        if(ind === index){
          return { src: "", loading: true }
        } else{
           return data
        }
      })
      setFormData({  ...formData, our_partners: update });
    }
    new BaseService().postUpload(filesData[0]).then((res) => {
      if (res?.data?.status) {
        if(type === "banners"){
          let update = formData?.banners?.map((data, ind)=> {
            if(ind === index){
              return {...data, src: res.data.url, loading: false}
            } else{
               return data
            }
          })
          setFormData({ 
            ...formData, 
            banners: update
          });
        } else if(type === "services"){
          let update = formData?.services?.map((data, ind)=> {
            if(ind === index){
              return {
                ...data,
                img: { src: res.data.url, loading: false }
              }
            } else{
               return data
            }
          })
          setFormData({ 
            ...formData, 
            services: update 
          });
        } else {
          let update = formData?.our_partners?.map((data, ind)=> {
            if(ind === index){
              if(data?.id){
                updateImage(data?.id, res.data.url)
              }
              return { src: res.data.url, loading: false }
            } else{
               return data
            }
          })
          setFormData({ 
            ...formData, 
            our_partners: update 
          });
        }
      }
    });
  };

  const handleServices = (e) =>{
    e.preventDefault();

    setLoading(true);
    let data = {
      service: formData.services?.filter(res=> !!res?.img?.src)?.map(res=>{
        return {
          image: res?.img?.src,
          title: res?.title
        }
      })
    };

    brandingService.createServices(data)?.then((res) => {
      if (res?.status === 201) {
        toast.success("Services Added Successfully");
      }
      setLoading(false);
    }).catch(()=> setLoading(false));
  }

  const handleWhatWeDo = (e) =>{
    e.preventDefault();

    setWWDLoading(true);
    let data = {
      description: draftToHtml(convertToRaw(formData?.wwd_description.getCurrentContent()))
    };

    brandingService.createWWD(data)?.then((res) => {
      if (res?.status === 201) {
        toast.success("What We Do Added Successfully");
      }
      setWWDLoading(false);
    }).catch(()=> setWWDLoading(false));
  }

  const handleBanners = (e) =>{
    e.preventDefault();

    setBannersLoading(true);
    let data = {
      banners: formData?.banners?.filter(res=> !!res.src)?.map(res=> {
        return {
          sub_title: res.sub_title,
          title: res?.title,
          image: res.src
        }
      })
    };

    if(editBanners){
      brandingService.updateBanners(data)?.then((res) => {
        if (res?.status === 200) {
          toast.success("Banners Updated Successfully");
        }
        setBannersLoading(false);
      }).catch(()=> setBannersLoading(false));
    } else {
      brandingService.createBanners(data)?.then((res) => {
        if (res?.status === 201) {
          toast.success("Banners Added Successfully");
        }
        setBannersLoading(false);
      }).catch(()=> setBannersLoading(false));
    }
  }

  const handleImages = (e) =>{
    e.preventDefault();

    setPartnersLoading(true);
    let data = {
      images: formData?.our_partners?.filter(res=> !!res?.src)?.map(res=> res?.src)
    };

    brandingService.createImages(data)?.then((res) => {
      if (res?.status === 201) {
        toast.success("Images Added Successfully");
      }
      setPartnersLoading(false);
    }).catch(()=> setPartnersLoading(false));
  }

  const updateImage = (id, imgSrc) =>{
    let data = {
      image: imgSrc
    };

    brandingService.updateImages(id, data)?.then((res) => {
      if (res?.status === 200) {
        toast.success("Image Updated Successfully");
      }
    }).catch(() => {});
  }

  const deleteBannerImg = (index) => {
    let update = formData?.banners?.map((info, ind)=>{
      if(ind === index){
        return {
          ...info,
          src: "",
          title: "",
          sub_title: ""
        }
      } else{
        return { ...info }
      }
    })
    setFormData({...formData, banners: update})
  }

  const deleteServicesImg = (index) => {
    let update = formData?.services?.map((info, ind)=>{
      if(ind === index){
        return {
          ...info,
          img: {...info?.img, src: "" }
        }
      } else{
        return { ...info }
      }
    })
    setFormData({...formData, services: update})
  }

  const deleteImg = (id) => {
    brandingService?.removeImages(id).then(res=>{
      if(res?.status === 200){
        toast.success("Image Removed Successfully")
        let update = formData?.our_partners?.map((info)=>{
          if(info?.id === id){
            return {loading: false, src: "" }
          } else{
            return { ...info }
          }
        })
        setFormData({...formData, our_partners: update})
      }
    })
  }

  return <>
  <Card>
      <Card.Body>
        <AvForm onValidSubmit={handleBanners}>
         <div className="banners mt-3">
            <h3>{Translate[lang]?.banners}</h3>
            <div className="mt-4">
                {formData?.banners?.map((banner, index)=> {
                    return <div className="row" key={index}> 
                      <div className="col-lg-12 col-sm-12 mb-3">
                        <div className="image-placeholder">
                          <div className="avatar-edit w-100 h-100">
                            <input
                              type="file"
                              className="w-100 h-100 d-block cursor-pointer"
                              style={{opacity: '0'}}
                              onChange={(e) => fileHandler(e, index, "banners")}
                              id={`imageUpload-${index}`}
                            />
                          </div>
                          <button
                        className="delete-img"
                        type="button"
                        style={{
                          left: lang === 'ar' ? '-13px' : "auto",
                          right: lang === 'en' ? '-13px' : "auto",
                        }}
                        onClick={() => deleteBannerImg(index)}
                      >
                        <i className="la la-trash"></i>
                      </button>
                          <div className="avatar-preview">
                            {!!banner.src ? (
                              <div id={`imagePreview-${index}`}>
                                <img
                                  id={`saveImageFile-${index}`}
                                  src={banner?.src}
                                  alt="icon"
                                />
                              </div>
                            ) : (
                              <div id={`imagePreview-${index}`}>
                                {(!banner.src && banner.loading)  && <Loader></Loader>}
                                {!banner?.src && (
                                  <img
                                    id={`saveImageFile-${index}`}
                                    src={uploadImg}
                                    alt="icon"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 mb-3">
                      <AvField
                        label ={Translate[lang]?.title}
                        name ='title'
                        type='text'
                        className='mb-5'
                        value={banner?.title}
                        errorMessage="Please enter a valid Title"
                        // validate={{
                        //   required: {
                        //     value: !!banner?.src,
                        //     errorMessage: Translate[lang].field_required
                        //   },
                        // }}
                        placeholder={Translate[lang]?.title}
                        onChange={(e)=> {
                          let data = e.target.value
                          let update = formData?.banners?.map((info, ind)=>{
                            if(ind === index){
                              return {
                                ...info,
                                title: data
                              }
                            } else{
                              return { ...info }
                            }
                          })
                          setFormData({...formData, banners: update})
                        }}
                      />
                      </div>
                      <div className="col-lg-6 col-sm-6 mb-3">
                      <AvField
                        label ={Translate[lang]?.sub_title}
                        name ='sub_title'
                        type='text'
                        className='mb-5'
                        value={banner?.sub_title}
                        errorMessage="Please enter a valid SubTitle"
                        // validate={{
                        //   required: {
                        //     value: !!banner?.src,
                        //     errorMessage: Translate[lang].field_required
                        //   },
                        // }}
                        placeholder={Translate[lang]?.sub_title}
                        onChange={(e)=> {
                          let data = e.target.value
                          let update = formData?.banners?.map((info, ind)=>{
                            if(ind === index){
                              return {
                                ...info,
                                sub_title: data
                              }
                            } else{
                              return { ...info }
                            }
                          })
                          setFormData({...formData, banners: update})
                        }}
                      />
                      </div>
                  </div>
                })}
                {/* <div className="col-lg-12 col-sm-12 d-flex" style={{
                    alignItems: "center", justifyContent: "center"
                }}>
                    <div className="add-client">
                        <i className="la la-plus cursor-pointer" onClick={()=>{
                            setFormData({...formData, banners: [...formData.banners, { src: "", title: "", sub_title: "", loading: false }]})
                        }}></i>
                    </div>
                </div> */}
            </div>
         </div>
    
         {isExist('branding') && <div className="d-flex justify-content-between mt-4">
            <Button variant="primary" type="submit" disabled={bannersLoading}>{Translate[lang]?.submit}</Button>
         </div>}
        </AvForm>
      </Card.Body>
    </Card>

    <Card>
        <Card.Body>
        <AvForm onValidSubmit={handleWhatWeDo}>
         <div className="row branding-description">
             <h3>{Translate[lang]?.what_we_do}</h3>
            <div className="col-lg-12 col-sm-12 mb-3">
              <label>{Translate[lang]?.description}</label>
              <Editor
                editorState ={formData?.wwd_description}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(e) => {
                  setFormData({...formData, wwd_description: e})
                }}
              />
               {/* <textarea
                  value={formData?.wwd_description}
                  className='d-block w-50'
                  style={{
                    height: '200px',
                    maxHeight: '200px',
                    borderColor: '#dedede',
                    borderRadius: '5px',
                    padding: '8px'
                  }}
                  errorMessage="Please enter a valid description"
                  validate={{
                    required: {
                      value:true,
                      errorMessage: Translate[lang].field_required
                    },
                  }}
                  placeholder={Translate[lang]?.description}
                  onChange={(e)=> setFormData({...formData, wwd_description: e.target.value})}
				        /> */}
            </div>
         </div>
         {isExist('branding') && <div className="d-flex justify-content-between mt-2">
            <Button variant="primary" type="submit" disabled={WWDLoading}>{Translate[lang]?.submit}</Button>
         </div>}
         </AvForm>
      </Card.Body>
    </Card>

    <Card>
        <Card.Body>
        <AvForm onValidSubmit={handleServices}>
         <div className="mt-3">
            <h3>{Translate[lang]?.services}</h3>
            <div className="row mt-4">
              {formData?.services?.map((service, index)=>{
                return <div className="col-lg-4 col-sm-12 mb-3">
                  <AvField
                  label ={Translate[lang]?.title}
                  name ='title'
                  type='text'
                  className='mb-5'
                  value={service?.title}
                  errorMessage="Please enter a valid Title"
                  placeholder={Translate[lang]?.title}
                  onChange={(e)=> {
                    let data = e.target.value
                    let update = formData?.services?.map((info, ind)=>{
                      if(ind === index){
                        return {
                          ...info,
                          title: data
                        }
                      } else{
                        return { ...info }
                      }
                    })
                    setFormData({...formData, services: update})
                  }}
				        />
                  <div className="image-placeholder mt-3">
                      <div className="avatar-edit w-100 h-100">
                        <input
                          type="file"
                          className="w-100 h-100 d-block cursor-pointer"
                          style={{opacity: '0'}}
                          onChange={(e) => fileHandler(e, index, 'services')}
                          id={`imageUpload${index}`}
                        />
                        {/* <label htmlFor={`imageUpload${index}`} name=""></label> */}
                      </div>
                      <button
                        className="delete-img"
                        type="button"
                        style={{
                          left: lang === 'ar' ? '-13px' : "auto",
                          right: lang === 'en' ? '-13px' : "auto",
                        }}
                        onClick={() => deleteServicesImg(index)}
                      >
                        <i className="la la-trash"></i>
                      </button>
                      <div className="avatar-preview">
                        {!!service?.img.src ? (
                          <div id={`imagePreview${index}`}>
                            <img
                              id={`saveImageFile${index}`}
                              src={service?.img?.src}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div id={`imagePreview${index}`}>
                            {(!service?.img.src && service?.img.loading)  && <Loader></Loader>}
                            {(!service?.img?.src && !service?.img.loading) && (
                              <img
                                id={`saveImageFile${index}`}
                                src={uploadImg}
                                alt="icon"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
              </div>
              })}
            </div>
         </div>
         {isExist('branding') &&<div className="d-flex justify-content-between mt-4">
            <Button variant="primary" type="submit" disabled={loading}>{Translate[lang]?.submit}</Button>
         </div>}
         </AvForm>
      </Card.Body>
    </Card>

    <Card>
      <Card.Body>
        <AvForm onValidSubmit={handleImages}>
         <div className="our-clients mt-3">
            <h3>{Translate[lang]?.images}</h3>
            <div className="row mt-4">
                {formData?.our_partners?.map((partner, index)=> {
                    return <div className="col-lg-2 col-sm-4 mb-3" key={index}>
                    <div className="image-placeholder">
                      <div className="avatar-edit w-100 h-100">
                        <input
                          type="file"
                          className="w-100 h-100 d-block cursor-pointer"
                          style={{opacity: '0'}}
                          onChange={(e) => fileHandler(e, index, "partners")}
                          id={`imageUpload-${index}`}
                        />
                      </div>
                      {!!partner?.src && <button
                        className="delete-img"
                        type="button"
                        style={{
                          left: lang === 'ar' ? '-13px' : "auto",
                          right: lang === 'en' ? '-13px' : "auto",
                        }}
                        onClick={() => deleteImg(partner.id)}
                      >
                        <i className="la la-trash"></i>
                      </button>}
                      <div className="avatar-preview">
                        {!!partner.src ? (
                          <div id={`imagePreview-${index}`}>
                            <img
                              id={`saveImageFile-${index}`}
                              src={partner?.src}
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div id={`imagePreview-${index}`}>
                            {(!partner.src && partner.loading)  && <Loader></Loader>}
                            {!partner?.src && (
                              <img
                                id={`saveImageFile-${index}`}
                                src={uploadImg}
                                alt="icon"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                })}
                <div className="col-lg-2 col-sm-4 d-flex" style={{
                    alignItems: "center", justifyContent: "center"
                }}>
                    <div className="add-client">
                        <i className="la la-plus cursor-pointer" onClick={()=>{
                            setFormData({...formData, our_partners: [...formData.our_partners, { src: "", loading: false }]})
                        }}></i>
                    </div>
                </div>
            </div>
         </div>
    
         {isExist('branding') &&<div className="d-flex justify-content-between mt-4">
            <Button variant="primary" type="submit" disabled={partnersLoading}>{Translate[lang]?.submit}</Button>
         </div>}
        </AvForm>
      </Card.Body>
    </Card>
    </>
}
export default Branding;