import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocialMediaService from "../../../../services/SocialMediaService";
import Loader from "../../../common/Loader";
import { SocialMediaLinks } from "../../../Enums/SocialMedia";
import { Translate } from "../../../Enums/Tranlate";
import uploadImg from "../../../../images/upload-img.png";
import BaseService from "../../../../services/BaseService";


const SocialMedia = ()=>{
    const [links, setLinks] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingPdfs, setLoadingPdfs] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [isAdd, setIsAdd] = useState(false)
    const [pdfs, setPdfs] = useState([
        {url: '', loading: false},
        {url: '', loading: false},
    ])    
    const Auth = useSelector(state=> state.auth?.auth)
    const lang = useSelector(state=> state.auth?.lang)
    const socialMediaService = new SocialMediaService()
    const isExist = (data)=> Auth?.admin?.admin_roles?.includes(data)

    useEffect(()=>{
        setLoadingData(true)
        socialMediaService?.getList()?.then(res=>{
            if(res.status === 200 && res.data?.data){
                let data = res.data?.data
                setLinks(data)
                setIsAdd(false)
            } else{
                let values={}
                SocialMediaLinks?.map(link=> values[link.value]= '')
                setLinks({...values})
                setIsAdd(true)
            }
            setLoadingData(false)
        })
        socialMediaService?.getPdfsList()?.then(res=>{
            if(res.status === 200){
                if(res?.data?.data?.length === 0){
                    return
                }
                let pdfsData = res?.data?.data?.map(pdf => {
                    return {
                        ...pdf,
                        loading: false
                    }
                })
                if(pdfsData?.length === 1){
                    setPdfs([...pdfsData, {url: '', loading: false}])
                    return
                }
                setPdfs(pdfsData)
            }
            setLoadingData(false)
        })
    },[])

    const inputHandler =(e)=>{
        setLinks({...links,[e.target.name]: e.target.value})
    }

    const fileHandler = (e, index) => {
        if(e.target.files?.length === 0){
          return
        }
        let filesAll = e.target.files;
        const filesData = Object.values(filesAll);
        
        let update = pdfs?.map((data, ind)=> {
            if(ind === index){
              return {url: "", loading: true}
            } else{
               return data
            }
        })
        setPdfs([...update ]);
        new BaseService().postUpload(filesData[0]).then((res) => {
          if (res?.data?.status) {
            let update = pdfs?.map((data, ind)=> {
                if(ind === index){
                  return {url: res.data.url, loading: false}
                } else{
                   return data
                }
              })
              setPdfs([ ...update ]);
          }
        });
    };

    const deleteClientImg = (index, id) =>{ 
        if(!!id){
            socialMediaService?.deletePdfsList(id).then(res=>{
                if(res?.status){
                    let update = pdfs?.map((data, ind)=> {
                        if(ind === index){
                        return {url: "", loading: false}
                        } else{
                        return data
                        }
                    })
                    setPdfs([...update ]);
                }
            })
        } else{
            let update = pdfs?.map((data, ind)=> {
                if(ind === index){
                return {url: "", loading: false}
                } else{
                return data
                }
            })
            setPdfs([...update ]);
        }
    }

    const onSubmit = (e)=> {
        e.preventDefault()

        let data = {}
        if(!!links.address) data['address'] = links.address
        if(!!links.snapchat) data['snapchat'] = links.snapchat
        if(!!links.facebook) data['facebook'] = links.facebook
        if(!!links.instagram) data['instagram'] = links.instagram
        if(!!links.whatsapp) data['whatsapp'] = links.whatsapp
        if(!!links.twitter) data['twitter'] = links.twitter
        if(!!links.linked_in) data['linked_in'] = links.linked_in
        if(!!links.gmail) data['gmail'] = links.gmail
        if(!!links.location) data['location'] = links.location
        if(!!links.call_us) data['call_us'] = links.call_us
        if(!!links.day_from) data['day_from'] = links.day_from
        if(!!links.day_to) data['day_to'] = links.day_to
        if(!!links.time_from) data['time_from'] = links.time_from
        if(!!links.time_to) data['time_to'] = links.time_to

        setLoading(true)
        socialMediaService?.create(data)?.then(res=>{
            if(res.status === 201){
                toast?.success(`${Translate[lang].added} ${Translate[lang].social_media} ${Translate[lang].successfully}`)
                setIsAdd(false)
            }
            setLoading(false)
        }).catch(error=> {
            toast.error(error)
            setLoading(false)
        })
    }
    const onPdfsSubmit = (e)=> {
        e.preventDefault()
        if(pdfs?.filter(res=> !res.url)?.length){
            toast.error('Upload Pdfs')
            return
        }
        let data = {
            url: pdfs?.map(res=> res.url)
        }

        setLoadingPdfs(true)
        socialMediaService?.createPdfsList(data)?.then(res=>{
            if(res.status === 201){
                toast?.success(`${Translate[lang].added} ${Translate[lang].successfully}`)
            }
            setLoadingPdfs(false)
        }).catch(error=> {
            toast.error(error)
            setLoadingPdfs(false)
        })
    }

    if(loadingData){
        return <Card className="py-5" style={{height: '300px'}}>
            <Card.Body>
                <Loader />
            </Card.Body>
      </Card>
    }

    return(<>
    <Card>
        <Card.Body className="position-relative">
            <form onSubmit={onSubmit}>
                <Row className="mb-3">
                {SocialMediaLinks?.map((link, index)=>{
                    if(link.value === "time_from" || link.value === "time_to"){
                        return <Col md={3} className='mb-3' key={index}>
                        <label className="text-label">
                            {Translate[lang][link.value]} :
                        </label>
                        <input
                            type="time"
                            name={link.value}
                            disabled={!isAdd}
                            style={{
                                background: !isAdd ? 'rgb(238 238 238)' : '#fff'
                            }}
                            required
                            className="form-control"
                            placeholder={Translate[lang][link.value]}
                            value={links[link?.value]}
                            onChange={(e)=> inputHandler(e)}
                        />
                    </Col>
                    } else {
                        return <Col md={6} className='mb-3' key={index}>
                        <label className="text-label">{Translate[lang][link.value]} :</label>
                        <input
                            type="text"
                            name={link.value}
                            disabled={!isAdd}
                            style={{
                                background: !isAdd ? 'rgb(238 238 238)' : '#fff'
                            }}
                            // required
                            className="form-control"
                            placeholder={Translate[lang][link.value]}
                            value={links[link?.value]}
                            onChange={(e)=> inputHandler(e)}
                        />
                    </Col>
                    }
                })}
                </Row>
            {isExist('home') && <div className="d-flex justify-content-end">
                {isAdd && <Button variant="primary" type="submit" disabled={loading}>
                    {Translate[lang].submit}
                </Button>}
                {!isAdd && <Button variant="primary" type="button" onClick={()=> setIsAdd(true)}>
                    {Translate[lang].edit}
                </Button>}
            </div>}
            </form>  
        </Card.Body>
    </Card>
    <Card>
        <Card.Body className="position-relative">
            <form onSubmit={onPdfsSubmit}>
                <Row className="mb-3">
                {pdfs?.map((pdf, index)=>{
                    return <Col md={3}>
                        <label className="mt-3">Pdf {index+1}</label>
                        <div className="image-placeholder m-0" style={{maxWidth: '100%'}}>
                          <div className="avatar-edit w-100 h-100">
                            <input
                              type="file"
                              className="w-100 h-100 d-block cursor-pointer"
                              style={{opacity: '0'}}
                              accept=".pdf"
                              onChange={(e) => fileHandler(e, index)}
                              id={`imageUpload${index}`}
                            />
                            {/* <label htmlFor={`imageUpload${index}`} name=""></label> */}
                          </div>
                          {!!pdf?.url && <button
                            className="delete-img"
                            type="button"
                            style={{
                              left: lang === 'ar' ? '-13px' : "auto",
                              right: lang === 'en' ? '-13px' : "auto",
                            }}
                            onClick={() => deleteClientImg(index, pdf?.id)}
                          >
                            <i className="la la-trash"></i>
                          </button>}
                          <div className="avatar-preview w-100" style={{height: '200px'}}>
                            {!!pdf.url ? (
                              <div id={`imagePreview${index}`}>
                                {/* <img
                                  id={`saveImageFile${index}`}
                                  src={pdf?.src}
                                  alt="icon"
                                /> */}
                                <i 
                                    className="la la-file-pdf"
                                    style={{fontSize: '6rem'}}
                                ></i>
                              </div>
                            ) : (
                              <div id={`imagePreview${index}`}>
                                {(!pdf.url && pdf.loading)  && <Loader></Loader>}
                                {(!pdf?.url && !pdf.loading) && (
                                  <img
                                    id={`saveImageFile${index}`}
                                    src={uploadImg}
                                    alt="icon"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        </Col>
                    })}
                </Row>
            {isExist('home') && <div className="d-flex justify-content-end">
                <Button variant="primary" type="submit" disabled={loadingPdfs}>
                    {Translate[lang].submit}
                </Button>
            </div>}
            </form>
        </Card.Body>
    </Card>
    </>)
}
export default SocialMedia;