export const SocialMediaLinks =[
    {label: 'Snapchat', value: 'snapchat'},
    {label: 'Facebook', value: 'facebook'},
    {label: 'Instagram', value: 'instagram'},
    {label: 'Whatsapp', value: 'whatsapp'},
    {label: 'Twitter', value: 'twitter'},
    {label: 'linked_in', value: 'linked_in'},
    {label: 'Gmail', value: 'gmail'},
    {label: 'Address', value: 'address'},
    {label: 'Call Us', value: 'call_us'},
    {label: 'Day From', value: 'day_from'},
    {label: 'Day To', value: 'day_to'},
    {label: 'Time From', value: 'time_from'},
    {label: 'Time To', value: 'time_to'},   
]